// color overrides
$blue: #2a3ef3;
$old-blue: #167df0;
$pink: #ff5757;
$yellow: #f6a200;
$deep-blue: #18065e;
$dark-blue: #0c003b;
$black: #0f0428;
$background-dark: #241f46;
$green: #42d17b;
$white: #ffffff;
$background-light: #fcfcff;
$background-grey: #fbfbfb;
$background-grey-light: #f6f6f6;
$lighter-grey: #f4f4f4;
$light-grey: #f0f0f3;
$greyer: #dadada;
$darker-grey-ada-compliant: #3d3d65;
$grey: #afadbe;
$grey-ada-compliant: #807f84;
$dark-grey-slightly: #6d6d76;
$dark-grey: #7c7c86;
$dark-grey-ada-compliant: #383737;
$dark-contrast: #524e6e;
$radius: 3px;
$big-radius: 6px;
// Priority
$primary: $deep-blue;
$info: $blue;
// $primary: $blue;
// $info: $deep-blue;
$warning: $yellow;
$dark: $black;
$light: $light-grey;
$success: $green;

// fonts
$family-serif: 'Montserrat', 'Arial', sans-serif;
$family-sans-serif: 'Roboto', 'Arial', serif;

// style guide - fonts
$break-max-desktop-plus: 1400px;
$break-max-desktop: 1216px;
$break-max-tablet: 1100px;
$break-max-phone: 768px;
$title-breakpoint: 768px;
$app-nav-width: 70px;

// Modal
$modal-background-background-color: rgba($black, 0.6);

// Link
$link: $blue;

// Button
$button-color: $white;
$button-background-color: $blue;
$button-hover-color: $white;
$button-focus-color: $white;
$button-active-color: $white;
$button-disabled-opacity: 1;
$button-disabled-background-color: $light-grey;

// Style Guide Spacing
$spacing-1: 0.25rem; // 4px
$spacing-2: 0.5rem; // 8px
$spacing-3: 1rem; // 16px
$spacing-4: 1.5rem; // 24px
$spacing-5: 2rem; // 32px
$spacing-6: 3rem; // 48px
$spacing-7: 5rem; // 80px


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.hero-announcement {
  align-items: center;
  background-color: var(--blue-gradient-fallback);
  background: var(--blue-gradient);
  color: var(--white);
  display: flex;
  justify-content: center;
  padding: var(--spacing-2);
}

.caption-bold {
  color: currentColor;
  font-family: var(--font-sans);
  font-size: var(--font-sm);
  font-weight: var(--font-medium);
  line-height: var(--line-xs);
}

.button-inverted-strong {
  background-color: transparent;
  border-radius: var(--rounded-full);
  border: 2px solid var(--white);
  color: var(--white);
  cursor: pointer;
  font-size: var(--font-sm);
  font-weight: var(--font-medium);
  line-height: var(--line-xs);
  margin-left: var(--spacing-2);
  padding: var(--spacing-1) var(--spacing-2);
  white-space: nowrap;
}
