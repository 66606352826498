// color overrides
$blue: #2a3ef3;
$old-blue: #167df0;
$pink: #ff5757;
$yellow: #f6a200;
$deep-blue: #18065e;
$dark-blue: #0c003b;
$black: #0f0428;
$background-dark: #241f46;
$green: #42d17b;
$white: #ffffff;
$background-light: #fcfcff;
$background-grey: #fbfbfb;
$background-grey-light: #f6f6f6;
$lighter-grey: #f4f4f4;
$light-grey: #f0f0f3;
$greyer: #dadada;
$darker-grey-ada-compliant: #3d3d65;
$grey: #afadbe;
$grey-ada-compliant: #807f84;
$dark-grey-slightly: #6d6d76;
$dark-grey: #7c7c86;
$dark-grey-ada-compliant: #383737;
$dark-contrast: #524e6e;
$radius: 3px;
$big-radius: 6px;
// Priority
$primary: $deep-blue;
$info: $blue;
// $primary: $blue;
// $info: $deep-blue;
$warning: $yellow;
$dark: $black;
$light: $light-grey;
$success: $green;

// fonts
$family-serif: 'Montserrat', 'Arial', sans-serif;
$family-sans-serif: 'Roboto', 'Arial', serif;

// style guide - fonts
$break-max-desktop-plus: 1400px;
$break-max-desktop: 1216px;
$break-max-tablet: 1100px;
$break-max-phone: 768px;
$title-breakpoint: 768px;
$app-nav-width: 70px;

// Modal
$modal-background-background-color: rgba($black, 0.6);

// Link
$link: $blue;

// Button
$button-color: $white;
$button-background-color: $blue;
$button-hover-color: $white;
$button-focus-color: $white;
$button-active-color: $white;
$button-disabled-opacity: 1;
$button-disabled-background-color: $light-grey;

// Style Guide Spacing
$spacing-1: 0.25rem; // 4px
$spacing-2: 0.5rem; // 8px
$spacing-3: 1rem; // 16px
$spacing-4: 1.5rem; // 24px
$spacing-5: 2rem; // 32px
$spacing-6: 3rem; // 48px
$spacing-7: 5rem; // 80px


$background: $white;

.container {
  // New bulma pushes stuff down
  z-index: 999;
}

.navbar {
  background-color: $background;
  height: 80px;

  @media screen and (max-width: $break-max-tablet) {
    height: 75px;
  }

  .container {
    @media screen and (max-width: $break-max-tablet) {
      width: initial;
    }
  }

  .navbar-menu {
    background-color: $white;

    @media screen and (max-width: $break-max-tablet) {
      height: 100%;
    }
  }

  .navbar-item {
    color: $dark;
  }

  .navbar-burger {
    background: none;
    height: 75px;
    width: 4rem;
    border: 0;

    &:focus:not(:active) {
      box-shadow: none;
    }

    span {
      height: 2px;
      background-color: #0f093b;
    }
  }

  .logo {
    padding: 0px;

    &:hover {
      background: none;
    }
  }

  .logo-svg {
    height: 2rem;
    max-height: 2rem;
    max-width: 100%;
  }

  .navbar-item img {
    max-height: 2rem;
  }

  .navbar-end {
    .is-tab {
      padding-left: 2rem;
      padding-right: 2rem;

      @media screen and (max-width: 1100px) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    .navbar-item {
      border-top: 4px solid transparent;
      border-bottom: none;
      background-color: $background;
      font-size: 0.9rem;
    }

    .navbar-item.nav-button {
      border: none;
    }

    .navbar-item a.is-tab,
    a.navbar-item.is-tab:hover {
      border-top: 4px solid #ff5757;

      @media screen and (max-width: $break-max-tablet) {
        background-color: $light-grey;
        border-color: transparent;
      }
    }

    .nav-button {
      border-radius: 3px;
      margin: 1.25rem;
      padding: 1rem;
      color: $white;
      font-weight: 500;
      background-color: $blue;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1), 0px 2px 12px 0px rgba(0, 0, 0, 0.1);

      @media screen and (max-width: $break-max-tablet) {
        margin: 0.5rem 1rem;
        padding: 1rem;
      }
    }
  }

  &.has-shadow {
    box-shadow: 0 1px 0px 0 rgba(15, 8, 59, 0.04);
  }
}

.hero-announcement {
  text-align: center;
}

.app-tab {
  font-weight: 500;
  color: $blue !important;
}
