// color overrides
$blue: #2a3ef3;
$old-blue: #167df0;
$pink: #ff5757;
$yellow: #f6a200;
$deep-blue: #18065e;
$dark-blue: #0c003b;
$black: #0f0428;
$background-dark: #241f46;
$green: #42d17b;
$white: #ffffff;
$background-light: #fcfcff;
$background-grey: #fbfbfb;
$background-grey-light: #f6f6f6;
$lighter-grey: #f4f4f4;
$light-grey: #f0f0f3;
$greyer: #dadada;
$darker-grey-ada-compliant: #3d3d65;
$grey: #afadbe;
$grey-ada-compliant: #807f84;
$dark-grey-slightly: #6d6d76;
$dark-grey: #7c7c86;
$dark-grey-ada-compliant: #383737;
$dark-contrast: #524e6e;
$radius: 3px;
$big-radius: 6px;
// Priority
$primary: $deep-blue;
$info: $blue;
// $primary: $blue;
// $info: $deep-blue;
$warning: $yellow;
$dark: $black;
$light: $light-grey;
$success: $green;

// fonts
$family-serif: 'Montserrat', 'Arial', sans-serif;
$family-sans-serif: 'Roboto', 'Arial', serif;

// style guide - fonts
$break-max-desktop-plus: 1400px;
$break-max-desktop: 1216px;
$break-max-tablet: 1100px;
$break-max-phone: 768px;
$title-breakpoint: 768px;
$app-nav-width: 70px;

// Modal
$modal-background-background-color: rgba($black, 0.6);

// Link
$link: $blue;

// Button
$button-color: $white;
$button-background-color: $blue;
$button-hover-color: $white;
$button-focus-color: $white;
$button-active-color: $white;
$button-disabled-opacity: 1;
$button-disabled-background-color: $light-grey;

// Style Guide Spacing
$spacing-1: 0.25rem; // 4px
$spacing-2: 0.5rem; // 8px
$spacing-3: 1rem; // 16px
$spacing-4: 1.5rem; // 24px
$spacing-5: 2rem; // 32px
$spacing-6: 3rem; // 48px
$spacing-7: 5rem; // 80px


$title-breakpoint: 768px;

.column {
  margin-right: 2rem;
  .office-hours-text {
    color: $background-dark;
  }
  @media screen and (max-width: $title-breakpoint) {
    width: 100%;
    padding-top: 0rem;
  }
}

.hero-footer {
  padding: 0;
  background-color: $white;
  border-top: 4px solid $light-grey;
  h3.footer-title {
    font-size: 18px;
    font-weight: 600;
    color: $darker-grey-ada-compliant;
    border-bottom: solid 2px $darker-grey-ada-compliant;
    margin-bottom: 32px;
    @media screen and (max-width: $title-breakpoint) {
      padding-top: 2rem;
    }
  }
  p.control {
    margin-left: 0px;
  }
  .links {
    ul {
      list-style: none;
      margin-left: 0px;
      a {
        color: $deep-blue;
        line-height: 30px;
      }
    }
  }
  .connect {
    .fab {
      font-size: 38px;
      padding-right: 13%;
      padding-bottom: 20px;
    }
    a {
      color: $deep-blue;
    }
    .social {
      display: block;
    }
  }

  .copyright {
    text-align: center;
    color: $darker-grey-ada-compliant;
    padding-top: 1rem;
    margin-top: 2rem;
    a {
      color: $darker-grey-ada-compliant;
    }
  }
}

.hero-footer.full-screen-marketing {
  border-top: 0px;
  .padded-content {
    padding-top: 0px;
  }
}

.padded-content {
  padding-top: 4rem;
  padding-bottom: 3rem;
  @media screen and (max-width: $title-breakpoint) {
    padding-top: 1rem;
  }
}

.country-section {
  color: $dark-grey;
  border: 1px solid $light-grey;
  background-color: $background-light;
  padding: 2rem;
  border-radius: 6px;
  margin-top: 3rem;
}

.copyright div {
  padding-top: 1rem;
}
